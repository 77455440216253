export const platform = __UNI_PLATFORM__
export const isH5 = __UNI_PLATFORM__ === 'h5'
export const isApp = __UNI_PLATFORM__ === 'app'
export const isMp = __UNI_PLATFORM__.startsWith('mp-')
export const baseUrl = __VITE_SERVER_BASEURL__

function getIsInWeChat() {
  const ua = isH5 && navigator && navigator.userAgent.toLowerCase()
  return !!ua && ua.includes('micromessenger')
}

const isInWeChat = getIsInWeChat()

function getDevicesInfo() {
  const { deviceType } = uni.getSystemInfoSync()
  if (isApp) return 'APP'
  if (deviceType === 'phone') {
    if (isInWeChat) return '微信手机端'
    if (isH5) return '手机浏览器'
    if (isMp) return '小程序手机端'
  }
  if (deviceType === 'pc') {
    if (isInWeChat) return '微信电脑端'
    if (isH5) return '电脑浏览器'
    if (isMp) return '小程序电脑端'
  }
  return deviceType + '-' + __UNI_PLATFORM__
}
const device = getDevicesInfo()

const PLATFORM = {
  deviceType: uni.getSystemInfoSync().deviceType,
  platform,
  isH5,
  isApp,
  isMp,
  baseUrl,
  isInWeChat,
  devicesInfo: device,
}
export default PLATFORM
