import { wxConfig } from '@/service/system'
import { useCommonStore } from '@/store'
import { currRoute } from '@/utils'
import PLATFORM from '@/utils/platform'
import qs from 'qs'

function useShareConfig({
  titleKey = 'wechatShareTitle_fd',
  descKey = 'wechatShareTitle_fdxq',
  title = undefined,
  desc = undefined,
  link = undefined,
  imgUrl = 'https://fd.cdwwl.com/assets/logo-BZDZP-U1.png',
  params = {},
}) {
  const commonStore = useCommonStore()

  const init = () => {
    const route = currRoute()
    let shareUrl = link
    if (!link) {
      shareUrl = PLATFORM.baseUrl + '/#' + route.path
      const paramsObj = Object.assign(route.query, params)
      if (Object.keys(paramsObj)) {
        const params = qs.stringify(paramsObj)
        shareUrl += '?' + params
      }
    }
    const titleText = title || commonStore.systemProfile[titleKey]
    const descText = desc || commonStore.systemProfile[descKey]
    // h5分享
    jWeixin.updateAppMessageShareData({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
    })
    jWeixin.updateTimelineShareData({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
    })
  }

  onShow(async () => {
    if (PLATFORM.isInWeChat) {
      await wxConfig().then((res) => {
        jWeixin.config({
          ...res.data,
          timestamp: res.data.timestamp + '',
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'checkJsApi'],
        })
      })
      if (!commonStore.systemProfile?.wechatShareTitle_fd) {
        await commonStore.handleGetSystemProfile()
      }
      init()
    }
  })
}

export default useShareConfig
