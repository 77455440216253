<template>
  <view
    class="w-100vw h-100vh flex flex-col justify-center overflow-hidden box-border"
    :style="`padding-top: ${safeAreaInsets.top + 40}px;background: #f6f6f6;`"
  >
    <view class="text-center text-pri leading-80rpx bg-white">
      您好，{{ title || '本功能' }}暂未开放，如有疑问请咨询客服。
    </view>
    <view class="flex-1 flex flex-col justify-around items-center">
      <view class="text-center text-pri leading-40rpx">
        {{ title || '本功能' }}正在努力建设中，尽情期待！
      </view>
      <view
        @click="handleClick"
        class="w-592rpx h-100rpx mb-20rpx center text-white bg-pri border-rd-2 fw-500"
      >
        联系客服
      </view>
    </view>
  </view>
</template>

<script lang="ts" setup>
import { kfUrl } from '@/constant'
const { safeAreaInsets } = uni.getWindowInfo()
defineProps<{
  title?: string
}>()

const handleClick = () => {
  uni.share({
    provider: 'weixin',
    openCustomerServiceChat: true,
    customerUrl: kfUrl,
  })
}
</script>

<style lang="scss" scoped></style>
