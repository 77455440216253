import { http } from '@/utils/http'

const { get } = http

export const getSystemProfile = () => get('/settings/')

export const wxConfig = () => {
  const url = location.href.split('#')[0]
  return get('/wechat/config/?url=' + encodeURIComponent(url) + '&wx_name=' + '车队网公众号')
}
