import { queryUserInfo } from '@/service/user'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({ isLogin: false, userInfo: {} }),
  actions: {
    setLogin(login: boolean) {
      this.isLogin = login
    },
    async getUserInfo() {
      const res = (await queryUserInfo()) as any
      this.userInfo = res.data
      if (res.data?.state === 'no') {
        this.isLogin = false
        uni.showToast({ title: '尚未登录，请登录', icon: 'none' })
      }
      return res.data
    },
  },
  persist: true,
})
