<template>
  <view @click="goDetail">
    <Card :overhead="data.top >= 1" :praiseMark="data.praise_mark">
      <view>
        <view
          :class="['content', props.showPrice ? '' : 'mb', 'p-12rpx', 'whitespace-pre-wrap']"
          :style="`background: ${props.dark ? '#e1e1e1' : props.clicked ? '#f7f7f7' : ''}`"
          v-html="data.content"
        ></view>
        <view style="text-align: right" v-if="props.showPrice">
          <view class="text-pri">
            ￥
            <text class="text-l">
              {{ !data.issue_fee || Number(data.issue_fee) === 0 ? '电议' : data.issue_fee }}
            </text>
          </view>
        </view>
      </view>
      <template v-slot:footer>
        <view class="footer">
          <view class="flex gap-16rpx items-center">
            <view
              class="origin w-48rpx text-size-20rpx h-48rpx border-rd-48rpx center whitespace-nowrap"
              :style="`background: url(${data.issue_avatar_url || imgUrlMap.defaultAvatar}) center center / cover no-repeat;`"
            ></view>
            <view class="font-500 ellipsis max-w-200rpx">{{ data.issue_nickname }}</view>
            <wd-tag mark type="primary" :bgColor="originColorMap[data.origin]" custom-class="space">
              {{ originMap[data.origin] }}
            </wd-tag>
          </view>
          <view class="time min-w-220rpx">
            {{ formatUnixTime(data.update_time) }}
          </view>
        </view>
      </template>
    </Card>
  </view>
</template>

<script setup>
import { originMap, originColorMap, imgUrlMap } from '@/constant'
import { formatUnixTime } from '@/utils'

const props = defineProps({
  clicked: Boolean,
  dark: Boolean,
  showPrice: Boolean,
  data: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['click'])

const goDetail = () => {
  emit('click', props.data.id)
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24rpx;
  color: #757575;

  .origin {
    color: white;
    background-size: cover;
  }
}
</style>
