import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePayStore = defineStore('pay', () => {
  /** 支付的结果 */
  const payResult = ref(null)
  /** 是否处于支付状态 */
  const onPaying = ref(false)

  /** 清空支付信息，为支付做准备 */
  const preparePay = () => {
    payResult.value = null
    onPaying.value = true
  }

  /** 恢复未支付状态 */
  const resetPayStatus = () => {
    payResult.value = null
    onPaying.value = false
  }

  return {
    payResult,
    onPaying,
    preparePay,
    resetPayStatus,
  }
})
