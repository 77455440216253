import { getSystemProfile } from '@/service/system'
import { getUserProfile } from '@/service/user'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from './user'

export const useCommonStore = defineStore(
  'common',
  () => {
    const canPlay = ref(true)
    const systemProfile = ref({ is_show_car: false, is_show: true })
    const wxInfo = ref({})
    const userProfile = ref({})

    const needRefresh = ref(false)

    const userStore = useUserStore()
    const handleGetUserProfile = async () => {
      try {
        const res = await getUserProfile()
        if (res.code === 200) {
          userProfile.value = res.data
          // userProfile.value.user.fly_list_visit = false
        } else {
          userProfile.value = {}
        }
        return res.data
      } catch (error) {
        userProfile.value = {}
        userStore.setLogin(false)
        userStore.userInfo = {}
      }
    }

    const handleGetSystemProfile = async () => {
      const res = await getSystemProfile()
      if (res.code === 200) {
        systemProfile.value = res.data
      }
      return res.data
    }

    const isActive = computed(() => {
      if (userProfile.value?.user?.fly_list_time) {
        return Date.now() < userProfile.value?.user?.fly_list_time * 1000
      } else {
        return false
      }
    })

    /** 还有多少天过期 */
    const expiredTime = computed(() => {
      if (!isActive.value) return 0
      if (userProfile.value?.user?.fly_list_time) {
        const tmp = userProfile.value?.user?.fly_list_time * 1000 - Date.now()
        return Math.floor(tmp / 1000 / 60 / 60 / 24)
      }
      return 0
    })

    /** 过期提醒，0-7天每天提醒一次，有当天的时间，说明提醒过了，不再提醒 */
    const expiredTimeRemind = ref('')

    const isAbnormal = computed(() => userStore.isLogin && !userProfile.value?.user?.fly_list_visit)

    const systemInfo = uni.getSystemInfoSync()

    return {
      isIos: systemInfo.osName === 'ios' && systemInfo.uniPlatform === 'mp-weixin',
      canPlay,
      handleGetUserProfile,
      handleGetSystemProfile,
      systemProfile,
      userProfile,
      isActive,
      expiredTime,
      expiredTimeRemind,
      wxInfo,
      /** 账号权限异常 */
      isAbnormal,
      /** 是否需要刷新列表，用于发布后回到列表页判断是否需要重新请求 */
      needRefresh,
    }
  },
  {
    persist: true,
  },
)
